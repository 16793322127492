import React from 'react';
import { makeStyles, createStyles, Theme, List, ListItem, ListItemText, Collapse, ListSubheader } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { navigate } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
      },
  }),
);

type MenuItem = {
    title: string, 
    slug: string
}

type MenuItemProps = {
    allItems: Array<MenuItem>;
    item: MenuItem;
}

const MenuItem = (props: MenuItemProps) => {
    const {item, allItems} = props;
    const level = item.slug.split("/").length;
    const classes = useStyles();

    let hasChildren: boolean = false;
    allItems.forEach((node) => {
        if (node.slug.startsWith(item.slug) && node.slug.split("/").length === level + 1) {
            hasChildren = true;
        }
    });

    const [open, setOpen] = React.useState(level <= 4 ? true : false);

    const handleClick = () => {
        setOpen(!open);
    };

    return <>
        <ListItem button  onClick={() => {navigate(item.slug)}}>
            <ListItemText primary={item.title} />
            {hasChildren ? open ? <ExpandLess onClick={handleClick} /> : <ExpandMore onClick={handleClick} /> : null}
        </ListItem>
        {hasChildren && <Collapse in={open} timeout="auto">
            <List component="div" disablePadding className={classes.nested}>
                <TreeMenu items={allItems} path={item.slug} />
            </List>
        </Collapse>}
    </>
}


type TreeMenuProps = {
    items: Array<MenuItem>;
    path: string;
}
const TreeMenu = (props: TreeMenuProps) => {
    const { path } = props;
    const level = path.split("/").length;
    const items: Array<MenuItem> = props.items.filter((item) => {
        let isItem: boolean = false;
    
        if (item.slug.startsWith(path)) {
            const itemLevel = item.slug.split("/").length;
            if (itemLevel === level + 1) {
                isItem = true;
            }
        }
    
        return isItem;
      })

      if (items.length === 0) {
          return null;
      }

      return <>
      {items.map((item) => (<MenuItem allItems={props.items} item={item} key={item.slug} />))}
      </>
}

type Props = {
    path: string;
    data: any;
}

const DocumentationTree = (props: Props) => {

    const allItems: Array<MenuItem> = props.data.allMarkdownRemark.edges.sort((a,b) => {
        const weightA = a.node.frontmatter?.weight ? a.node.frontmatter.weight : 1;
        const weightB = b.node.frontmatter?.weight ? b.node.frontmatter.weight : 1;

        if (weightA < weightB) {
            return -1
        } else {
            return 1;
        }

    }).map(({node}) => ({title: node.frontmatter.title, slug: node.fields.slug}));

    return <List component="nav" aria-label="main mailbox folders">
            <ListSubheader>Documentation</ListSubheader>
            <TreeMenu path={props.path} items={allItems} />
        </List>
}

export default DocumentationTree;