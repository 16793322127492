import React from "react"
import { PageProps, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Typography, makeStyles, Theme, createStyles, Container, Grid, Drawer, Hidden, Fab } from "@material-ui/core"
import Section from "../components/Section";
import SEO from "../components/seo";
import DocumentationTree from "../components/DocumentationTree";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
        wrapper: {
            display: "flex",
        },
        side: {
            width: 300,
            minHeight: "100vh",
            borderRight: "1px solid grey",
        },
        drawerPaper: {
            width: drawerWidth,
            position: "static",
            paddingTop: theme.spacing(4),
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        content: {
            flexGrow: 1,
            flexBasis: "auto",
            width: `calc(100% - ${drawerWidth}px)`,
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
);

// const container = window !== undefined ? () => window().document.body : undefined;

export default function IndexRoute(props: any) {
    const classes = useStyles();

    const data = props.data;
    const post = data.markdownRemark;

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    }



  return (
    <Layout>
        <SEO title={post.frontmatter.title} />
        <div className={classes.wrapper}>

        <Hidden smUp implementation="css">
          <Drawer

            variant="temporary"
            anchor={"left"}
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
                <DocumentationTree path="/docs/" data={data} />
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="js">
          <Drawer
                className={classes.drawer}
                classes={{
                paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
          >
            <DocumentationTree path="/docs/" data={data} />
          </Drawer>
        </Hidden>

        <main className={classes.content}>
            <Section>
                <Container maxWidth={"md"}>
                    <Typography variant="h1" >{post.frontmatter.title}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </Container>
            </Section>
        </main>


            {/* <Hidden smDown>
                <div className={classes.side}>
                        <DocumentationTree path="/docs/" data={data} />
                </div>
            </Hidden> */}





        </div>

        <Hidden smUp>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleDrawerToggle}>
                {drawerOpen ? <CloseIcon /> : <FormatListBulletedIcon />}
            </Fab>
        </Hidden>

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              weight
            }
          }
        }
      }
  }
`